// ROUTES
import NetworkLayer from '@jackcom/app-network-layer';
// 
const METHODS = NetworkLayer.METHODS;
const ENV = process.env.REACT_APP_API_ENV;

export default {
    checkCustomerHasOrder: {
        url: p => `${baseURL(ENV)}/oem/users/${p.userId}/orderDetails`,
        authenticate: true
    },
    checkHasStripeSubscription: {
        url: p => `${baseURL(ENV)}/users/${p.userId}/stripe/customer`,
        authenticate: true
    },
    checkHasBlueSnapSubscription: {
        url: p => `${baseURL(ENV)}/users/${p.userId}/bluesnap/shopper`,
        authenticate: true
    },
    checkHasRecievedAdditionalSensors: {
        url: p=> `${baseURL(ENV)}/oem/users/${p.userId}/events?eventType=1&partnerId=${p.partnerId}`,
        authenticate: true
    },
    getClubCompositions: {
        url: p => `${baseURL(ENV)}/oem/partners/${p.partnerId}/formData`,
        authenticate: true
    },
    getUser: {
        url: p =>  `${baseURL(ENV)}/users/${p.userId}`,
        authenticate: true
    },
    submitOrder: {
        url: p => `${baseURL(ENV)}/oem/users/${p.userId}/orders`,
        authenticate: true,
        method: METHODS.POST
    },
    submitOrderFormEvent: {
        url: p => `${baseURL(ENV, "logs")}/userEvents/oemOrderFormEvents`,
        authenticate: true,
        method: METHODS.POST
    },
    getToken: {
        url: p => `${baseURL(ENV, "auth")}/tokens`,
        method: METHODS.POST,
    }
}

function baseURL(env = "iqa", api) {
    const ENV = env === "prd" ? "" : `${env}.`;
    const SUFFIX = `arccosgolf.com`;
    const ENV_URL = `https://${ENV}api.${SUFFIX}`;
    const AUTH_URL = `https://${ENV}authentication.${SUFFIX}`;
    const LOGS_URL = `https://${ENV}logs.${SUFFIX}`;
    return api === "auth" ? AUTH_URL 
        : api === "logs" ? LOGS_URL 
        : ENV_URL;
}