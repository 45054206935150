// Model Definitions: mainly used for linting

// Server 

export interface ClubData {
    partnerId: number;
    oemModelId: number;
    year: number;
    name: string;
    imgUrl: string | null;
    hybridModelId: number;
    ironModelId: number;
    wedgeModelId: number;
}

export interface GripData {
    gripTypeId: number;
    name: string;
    sku: string;
    upc: string;
    imgUrl: string | null;
}

export interface SetCompositionData {
    setCompositionId: number;
    name: string;
    noOfClubs: number;
    clubTypes: string[];
}

export interface SensorKitData {
    sensorKitId: number;
    partnerId: number;
    sku: string;
    upc: string;
    noOfNonPutterSensors: number;
}

export interface OrderData {
    partnerId: number;
    oemModelId: number;
    gripTypeId: number;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zip: string;
    country: string;
    upc: string;
    setCompositionId: number;
    sku: string;
    phone: string;
    quantity: number;
    email: string;
}

// Internal (App, AppState)

export interface Action {
    type: string;
    payload: any;
}

// Used in views
export const SmartSensor = {
    name: "Arccos Smart Sensors",
    description: "Easily screws into the grip-end of any club and requires no installation"
}
export const SmartGrip = {
    name: "Cobra Connect Smart Grips",
    description: "Sensor is seamlessly embedded into the grip: requires standard grip installation"
}

export const Errors = {
    WithCode: code => Errors[code] || Errors["00000"],
    "00000": "Internal error",
    "00001": "Not an existing customer", // not a stripe customer
    "00002": "Customer has order pending", 
    "00003": "Customer has extra sensors",
    "40004": "Invalid partner specified",
    "40003": "Smart Grip Orders can only ship to USA",
}

export class PartnerIds {
    static PARTNER_MAPPING = {
        '0': { name: 'Arccos' },
        '1': { name: 'Cobra' },
        '2': { name: 'TaylorMade' },
        '3': { name: 'Ping' }
    }

    static GET_NAME_FOR_PARTNER = (partnerId) => {
        return PartnerIds.PARTNER_MAPPING[`${partnerId}`].name
    }
}