import React, { Component } from 'react';

import SVGCrown from '../components/SVGCrown'

/**
 * `AsyncLoader` helps you load components asynchronously. Very useful for breaking
 * up your app bundle on compilation. To use, change `import MyFile from 'path/to/MyFile'`
 * to `const MyFile = AsyncLoader(() => import('path/to/MyFile'))`
 * 
 * @param {Function} importStatement 
 */
export default function AsyncLoader(importStatement: Function): typeof Component {

    if (!importStatement) throw new Error("Could not instantiate 'AsyncLoader' due to Missing Component");

    return class AsyncComponent extends Component {
        mounted = false

        state = {
            component: null,
            error: false
        }

        componentDidMount() {
            this.mounted = true;

            importStatement()
                .then(this.onImported)
                .catch(e => this.setState({ error: true, stack: e }))
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        onImported = res => {
            if (this.mounted) return this.setState({ component: res.default })
        }

        render() {
            // Error
            if (this.state.error) {
                console.error(this.state.stack)
                return (<p>Error Loading document</p>);
            }

            // Loading/Component import in progress
            if (!this.state.component) return (
                <div className="view--centered">
                    <SVGCrown loading={true} />
                </div>);

            // Component has been imported
            const WrappedComponent = this.state.component;
            return (<WrappedComponent {...this.props} />);
        }
    }
}