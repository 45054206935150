import NetworkLayer from '@jackcom/app-network-layer';
import { createBrowserHistory } from 'history';
import { OrderData, PartnerIds } from './models';
// 
import Routes from './routes';

export const history = createBrowserHistory();

// Server Request Routes
const APIConfig = new NetworkLayer.APIConfig(Routes);

// Server Requests/Data Fetchers

export function checkHasOrder(token, userId): Promise < Boolean > {
    return APIConfig.checkCustomerHasOrder({ token, userId })
        .then(res => true)
        .catch(promiseBooleanOnFail);
}

export function checkHasStripeSubscription(token, userId, partnerId, fromCEDash): Promise < Boolean > {
    return APIConfig.checkHasStripeSubscription({ token, userId })
        .then(res => {
            let partnerName = PartnerIds.GET_NAME_FOR_PARTNER(partnerId),
                partnerRegEx = new RegExp(partnerName, 'i'),
                whitelistRegEx = new RegExp("whitelist", 'i'),
                subscriptions = res.subscriptions,
                subscriptionType = !subscriptions ? null : subscriptions[0].plan.metadata.subscriptionType,
                allowWhitelist = process.env.REACT_APP_ALLOW_WHITELIST === 'true';

            console.log('stripe-allow-whitelist',allowWhitelist)
            console.log('stripe-whitelist',whitelistRegEx.test(subscriptionType))
            console.log('stripe-partner',partnerRegEx.test(subscriptionType))
            console.log(`stripe-subscriptionType`, subscriptionType)

            if (allowWhitelist && whitelistRegEx.test(subscriptionType)) {
                console.log('stripe-white')
                return true
            }

            if (fromCEDash && subscriptionType){
                console.log('stripe-ce')
                return true
            }

            if (partnerRegEx.test(subscriptionType) && !whitelistRegEx.test(subscriptionType)) {
                console.log('stripe-partner')
                return true
            }
            return false
        })
        .catch(promiseBooleanOnFail);
}
export function checkHasBlueSnapSubscription(token, userId, partnerId, fromCEDash): Promise < Boolean > {
    return APIConfig.checkHasBlueSnapSubscription({ token, userId })
        .then(res => {
            let partnerName = PartnerIds.GET_NAME_FOR_PARTNER(partnerId),
                partnerRegEx = new RegExp(partnerName, 'i'),
                whitelistRegEx = new RegExp("whitelist", 'i'),
                subscriptions = res.subscriptions,
                subscriptionType = !subscriptions ? null : subscriptions[0].subscriptionType,
                allowWhitelist = process.env.REACT_APP_ALLOW_WHITELIST === 'true';

            console.log('allow-whitelist',allowWhitelist)
            console.log('whitelist',whitelistRegEx.test(subscriptionType))
            console.log('partner',partnerRegEx.test(subscriptionType))
            console.log('subscriptionType',subscriptionType)

             if (allowWhitelist && whitelistRegEx.test(subscriptionType)) {
                console.log('blue-white')
                return true
            }

            if (fromCEDash && subscriptionType){
                console.log('blue-ce')
                return true
            }

            if (partnerRegEx.test(subscriptionType) && !whitelistRegEx.test(subscriptionType)) {
                console.log('blue-partner')
                return true
            }
            return false
        })
        .catch(promiseBooleanOnFail);
}

export function checkHasRecievedAdditionalSensors(token, userId, partnerId): Promise < Boolean > {
    return APIConfig.checkHasRecievedAdditionalSensors({ token, userId, partnerId })
        .then(res => {
            return res.events.length > 0
        })
        .catch(promiseBooleanOnFail);
}

export function getAccessKey() {
    return ({
        I: "d839664b53a54e018e362229471f0813",
        K: "D61B3D5DC63D10BFE98AF6F8175D201BD37EC6F9"
    })
}

export function getClubCompositions(token: string, partnerId: number = 1): Promise < SetCompositionData > {
    return APIConfig.getClubCompositions({ token, partnerId });
}

export async function getToken(): Promise < SetCompositionData > {
    const { I, K } = getAccessKey();
    const { token } = await APIConfig.getToken({ clientId: I, accessKey: K });
    return token;
}

export function getUser(token, userId){
    return APIConfig.getUser({ token, userId });
}

export async function submitOrder(token: string, order: OrderData) {
    return APIConfig.submitOrder(Object.assign({ token }, { ...order }))
        .catch(e => Promise.reject(e.error ? e.error : e))
}

export function submitOrderFormEvent(token, userId, partnerId, eventType){
    return APIConfig.submitOrderFormEvent({token, userId, partnerId, eventType});
}

/* Helpers */
function promiseBooleanOnFail(e) {
    // console.log(e);
    return false;
}