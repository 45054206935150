import React, { PureComponent } from 'react';

import "./SVGCrown.css"

export default class SVGCrown extends PureComponent {
    constructor (props){
      super(props)
      this.crownPath = React.createRef();
      this.state = {pathLength:0}
    }
    
    static defaultProps = {
        size: 100,
        color: "#5db446",
        stroke: null,
        passedClasses: "",
        loading: false
    }

    componentDidMount(){
      let pathLength = this.crownPath.current.getTotalLength()
      this.setState(({pathLength}))
    }
    

    render() {
        const { size, color, passedClasses, stroke, loading } = this.props; 
        const { pathLength } = this.state;

        let height = 9*size/10, width = size*2, midX = width/2
        let pointHeight = height/4, sidePointHeightDiff = height/10, sidePointDist = 7*width/24
        let diameter = height/5, radius = diameter/2 , sideCircleAngle = 60
        let sideCircleX = Math.cos(Math.PI*sideCircleAngle/180)*diameter, sideCircleY = Math.sin(Math.PI*sideCircleAngle/180)*diameter
        
        const path = `M${midX} ${pointHeight} 
                      L${midX + sidePointDist/2} ${height} 
                      L${midX + sidePointDist} ${pointHeight + sidePointHeightDiff}
                      A ${radius} ${radius} 0 0 1 ${midX + sidePointDist + sideCircleX } ${pointHeight + sidePointHeightDiff - sideCircleY}
                      A ${radius} ${radius} 0 0 1 ${midX + sidePointDist} ${pointHeight + sidePointHeightDiff}
                      L${midX} ${height}
                      L${midX - sidePointDist} ${pointHeight + sidePointHeightDiff}
                      A ${radius} ${radius} 0 0 1 ${midX - sidePointDist - sideCircleX} ${pointHeight + sidePointHeightDiff - sideCircleY}
                      A ${radius} ${radius} 0 0 1 ${midX - sidePointDist } ${pointHeight + sidePointHeightDiff}
                      L${midX - sidePointDist/2} ${height}
                      L${midX} ${pointHeight}
                      A ${radius} ${radius} 0 1 0 ${midX} ${pointHeight - diameter}
                      A ${radius} ${radius} 0 1 0 ${midX} ${pointHeight}`;
        const classNames = (`crown-path ${loading ? "loading" : ""}`);
        return (
          <svg className = {passedClasses} width={width} height={size} >
              <g transform-origin={"center"} >
                  <path
                      className={classNames}
                      d={path}
                      stroke={color}
                      strokeWidth={stroke || size/20}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      ref={this.crownPath}
                      strokeDasharray = {loading ? pathLength/2 : 0}
                      strokeDashoffset = {loading ? pathLength : 0}
                  />
              </g>
          </svg>
        )
    }
}