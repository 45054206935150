import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
// 
import { history } from './network';
// 
import './Reset.css';
import './App.css';
import './Routes.css';
import './Buttons.css';
import './Views.css';
// ROUTES
import AsyncLoader from './routes/AsyncLoader';
const Home = AsyncLoader(() =>
  import ('./routes/Home')
);


const InvalidRoute = AsyncLoader(() =>
  import ('./routes/InvalidRoute'));

const Redirect = AsyncLoader(() =>
  import ('./routes/Redirect')
);



class App extends Component {

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/orders/:userId/:subview" component={Home} />
          <Route path="/orders/:userId/" component={Home} />
          <Route exact path="/orders" component={Home} />
          <Route path="/redirect" component={Redirect} />
          <Route path="/not-found" component={InvalidRoute} />
          <Route exact path="*" component={InvalidRoute} />
        </Switch>
      </Router>
    );
  }
}


export default App;